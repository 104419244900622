<template>
	<div class="resource">
		<div class="banner">
			<img class="bannerBg" src="https://image.ceekee.com/pc/tripwise/2021014_1.png">
			<img class="fontImg" src="https://image.ceekee.com/pc/tripwise/20210115_4.png">
		</div>
		
		<div class="solutionBox content">
			<div class="sectionOneInfo">
				<img src="https://image.ceekee.com/pc/tripwise/20200110_10.png">
				<p>机票</p>
				<span>Flight</span>
			</div>
			<div class="sectionOneInfo">
				<img src="https://image.ceekee.com/pc/tripwise/20200110_11.png">
				<p>酒店</p>
				<span>Hotel</span>
			</div>
			<div class="sectionOneInfo">
				<img src="https://image.ceekee.com/pc/tripwise/20200110_12.png">
				<p>火车票</p>
				<span>Train</span>
			</div>
			<div class="sectionOneInfo">
				<img src="https://image.ceekee.com/pc/tripwise/20200110_13.png">
				<p>用车</p>
				<span>Car Service</span>
			</div>
		</div>	
		
		<div class="sectionOne">
			<div class="sectionOneLeft sectionTitle">
				<p>机票</p>
				<span>丰富的运价来源，覆盖全球航线，直连多家航司及优质供应商，库存实时准确</span>
			</div>
			<div class="sectionOneRight">		
				<div class="boxLeft right">
					<div class="boxli">
						<p>产品丰富</p>
						<span>提供多样化机票预订产品，比价便捷</span>
						<span>支持大客户协议托管，退改灵活</span>
						<span>通过全额行程单，可抵进项税</span>
					</div>
					
					<div class="boxli four">
						<p>智能管控</p>
						<span>票价、舱等多维度管控设置</span>
						<span>提前天数预订，特殊管控</span>
					</div>					
				</div>
				
				<div class="boxLeft">
					<div class="boxli on">
						<p>优势资源</p>
						<span>直连多家航司</span>
						<span>商旅独享价格优势，90%低于ota</span>
						<span class="on">国际机票询价模式，价格更低</span>
					</div>
						
					<div class="boxli two">
						<p>强大技术</p>
						<span>极速精准搜索航班信息</span>
						<span>资源PK机制，只输出最低价</span>
						<span>支持在线订/退/改，自助化操作</span>
						<span class="on">超标推荐，快速找到不超标航班</span>
					</div>
				</div>
			</div>
		</div>	
		
		<div class="sectionTwo">
			<div class="sectionTwoLeft">
				<div class="boxLeft">
					<div class="boxli">
						<p>优势资源</p>
						<span>覆盖全球120万家酒店</span>
						<span>直连华住、如家、锦江、亚朵、尚客优等酒店集团</span>
					</div>
				</div>	 
				
				<div class="boxLeft Two">
					<div class="boxli on">
						<p>灵活支付</p>
						<span>对公支付，统一开大发票</span>
						<span class="on">支持超标自付，满足员工入住体验</span>
					</div>
					<div class="boxli">
						<p>优质体验</p>
						<span>享含早、无押金、延迟退房等商旅服务</span>
						<span>房态准确，订单即时确认</span>
						<span>支持企业协议酒店托管，价格专享</span>
					</div>
				</div>	
				
				<div class="boxLeft">
					<div class="boxli">
						<p>智能管控</p>
						<span>房价、星级、城市多维度管控设置</span>
						<span>提前天数预订，特殊管控</span>
					</div>
				</div>	 
			</div>	
				
			<div class="sectionTwoRight sectionTitle">
				<p>酒店</p>
				<span>优质OTA资源，直连多家酒店集团实时库存，一秒确认下单</span>
			</div>
		</div>	
		
		<div class="sectionThree">
			<div class="sectionThreeLeft sectionTitle">
				<p>火车票</p>
				<span>全面接入12306<br>资源统一管控，告别分散预订</span>
			</div>
			<div class="sectionThreeRight">		
				<div class="boxLeft right">
					<div class="boxli on">
						<p>优质体验</p>
						<span>支持在线选座</span>
						<span>支持多档位抢票</span>
						<span class="on">1-10秒内极速出票</span>
					</div>
					
					<div class="boxli">
						<p>智能管控</p>
						<span>高铁/动车/快车，不同坐席管控设置</span>
						<span>提前天数预订，特殊管控</span>
					</div>					
				</div>
				
				<div class="boxLeft">
					<div class="boxli">
						<p>预订便捷</p>
						<span>支持在线订/退/改，自助化操作</span>
						<span>支持12306账号预订和免登录代订服务</span>
					</div>
						
					<div class="boxli two">
						<p>取票灵活</p>
						<span>支持电子自取票或纸质配送票</span>
						<span>商旅代取票，刷身份证进站，票根统一寄送</span>
					</div>
				</div>
			</div>
		</div>	
		
		<div class="sectionFour">
			<div class="sectionFourLeft">
				<div class="boxLeft">
					<div class="boxli on">
						<p>灵活叫车</p>
						<span>提供实时叫车、预约叫车两种模式</span>
					</div>
				</div>	 
				
				<div class="boxLeft Two">
					<div class="boxli">
						<p>放心乘坐</p>
						<span>司乘隐私号、一键报警、添加紧急联系人</span>
					</div>
					<div class="boxli">
						<p>优质服务</p>
						<span>同滴滴一样的用车体验</span>
					</div>
				</div>	
				
				<div class="boxLeft last">
					<div class="boxli">
						<p>行程透明</p>
						<span>行程记录，出行数据透明</span>
						<span>提供6%增值税专用发票，可抵进项税</span>
					</div>
				</div>	 
			</div>	
				
			<div class="sectionFourRight sectionTitle">
				<p>用车</p>
				<span>接入滴滴用车，所有体验同滴滴服务范围覆盖全国</span>
			</div>
		</div>	
		
		<div class="sectionFive">
			<div class="sectionFiveLeft sectionTitle">
				<p>增值服务</p>
				<span>差旅管家提供企业团建、定制旅行、会议、团队用房、团队机票、礼品定制等多种增值服务</span>
			</div>
			<img src="https://image.ceekee.com/pc/tripwise/20200110_16.png">
		</div>	
		<Partner></Partner>
	</div>
</template>

<script>
	import Partner from "@/components/Partner";
	export default {
		components: {
		  Partner
		}
	}
</script>

<style scoped lang="less">
	.resource{	
		.banner{
			width: 100%;
			overflow: hidden;
			position: relative;
			.bannerBg{				
				width: 100%;
			}
			.fontImg{
				position: absolute;
				width: 26%;
				top: 28%;
				left: 37%;
			}
		}
		.sectionOne{
			width: 80%;
			margin: 80px auto 50px;
			height: auto;
			overflow: hidden;
			.sectionOneLeft{
				float: left;
				margin-left: 2%;
			}
			.sectionOneRight{
				width: 75%;
				float: right;	
				.boxLeft{
					width: 50%;
					max-width: 550px;
					float: right;
					&.right{
						margin-top: 40px;
					}
					.boxli{					
						&.two{
							width: 70%;
							float: right;
						}
						&.four{
							width: 58%;
						}
					}
				}		
			}
		}
		.sectionTwo{
			width: 80%;
			padding: 80px 10% 60px;
			height: auto;
			overflow: hidden;
			background: url(https://image.ceekee.com/pc/tripwise/20200110_15.png) center no-repeat;
			background-size: cover;
			.sectionTwoLeft{
				width: 70%;
				float: left;
				.boxLeft{
					width: 30%;
					margin-top: 135px;
					&.Two{
						margin-top: 0;
					}
					max-width: 400px;
					float: left;
					.boxli{
						padding: 40px 9%;
						box-shadow:none;
						background: #fff;
						margin: 0 30px 30px 0;
					}
				}
			}
			.sectionTwoRight{
				float: left;
				p,span{
					color: #fff;
				}
				
			}
		}
		.sectionThree{
			width: 80%;
			padding: 80px 9% 60px;
			height: auto;
			overflow: hidden;
			.sectionThreeLeft{
				margin: 280px 0 0 3%;
				float: left;
			}
			.sectionThreeRight{
				width: 75%;
				float: right;	
				.boxLeft{
					width: 50%;
					max-width: 620px;
					float: right;
					margin-top: 9%;
					&.right{
						margin-top: 0;
					}
					.boxli{		
						&.on{
							width: 45%;
						}
						&.two{
							width: 45%;
							float: right;
						}						
					}
				}		
			}
		}
		.sectionFour{
			width: 90%;
			padding: 80px 5% 60px;
			height: auto;
			overflow: hidden;
			background: url(https://image.ceekee.com/pc/tripwise/20200110_14.png) center no-repeat;
			background-size: cover;
			.sectionFourLeft{
				width: 68%;
				float: left;
				.boxLeft{
					width: 30%;
					margin-top: 10%;
					&.last{
						margin-top: 9%;
					}
					&.Two{
						margin-top: 0;
					}
					max-width: 400px;
					float: left;
					.boxli{
						padding: 40px 9%;
						box-shadow:none;
						background: #fff;
					}
				}
			}
			.sectionFourRight{
				float: left;
				margin-top: 10%;
				p,span{
					color: #fff;
				}
				
			}
		}
		.sectionFive{
			width: 70%;
			padding: 100px 15% 80px;
			height: auto;
			overflow: hidden;
			.sectionFiveLeft{
				width:45%;
				max-width:400px;
				margin-top: 8%;
				float: left;
			}
			img{
				width: 50%;
				max-width: 515px;
				float: right;
			}
		}
		
		.boxLeft{
			.boxli{
				padding: 30px 7%;
				margin:0 0 30px 30px;
				box-shadow: 0px 4px 18px rgba(235, 235, 235, 0.5);
				border-radius: 10px;
				transition: all 0.5s;
				cursor: pointer;
				p{
					transition: all 0.5s;
					font-size: 30px;
					line-height: 45px;
					color: #222222;
					margin-bottom: 10px;
				}
				span{
					transition: all 0.5s;
					font-size: 16px;
					line-height: 28px;
					color: #999999;
					padding-left: 24px;
					display: block;
					position: relative;
					margin-bottom: 5px;
					&:hover{
						color:#FFBE2E!important;
					}
					&::after{
						content: '';
						transition: all 0.5s;
						position: absolute;
						left: 0;
						top: 11px;
						width: 8px;
						height: 8px;
						background: #0079FF;
						border-radius: 50%;
					}
				}
				&:hover{
					background: #007AFF!important;
					color: #fff;
					p{
						color: #fff;
					}
					span{
						color: #fff;
						&::after{
							content: '';
							background: #fff;
						}
					}
				}
			}
		}
		.sectionTitle{
			width: 19%;		
			max-width: 336px;
			margin-top: 180px;
			&>p{
				font-size: 32px;
				color: #222;
				&::after{
					content: '';
					display: block;
					width: 50px;
					height: 5px;
					margin: 5px 0 10px;
					background: #FFBE2E;
				}				
			}
			span{
				color: #999999;
				display: block;
				font-size: 16px;				
			}
		}
	}
	
	@media screen and (max-width:1600px) {
		.resource .sectionTwo .sectionTwoLeft{width: 81%;}
		.resource .sectionFour	.sectionFourLeft{width: 75%};
		.resource .sectionTwo .sectionTwoLeft .boxLeft{width: 32%;}
	}
	
	@media screen and (max-width:1300px) {
		.resource .sectionOne .sectionOneRight .boxLeft .boxli.four{width: 64%}
		.resource .sectionOne .sectionOneRight .boxLeft .boxli.two{width: 73%;}
	}
	
</style>	
