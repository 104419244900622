import { render, staticRenderFns } from "./Partner.vue?vue&type=template&id=3e990df1&scoped=true"
var script = {}
import style0 from "./Partner.vue?vue&type=style&index=0&id=3e990df1&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e990df1",
  null
  
)

export default component.exports