var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resource"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('Partner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"bannerBg",attrs:{"src":"https://image.ceekee.com/pc/tripwise/2021014_1.png"}}),_c('img',{staticClass:"fontImg",attrs:{"src":"https://image.ceekee.com/pc/tripwise/20210115_4.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solutionBox content"},[_c('div',{staticClass:"sectionOneInfo"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200110_10.png"}}),_c('p',[_vm._v("机票")]),_c('span',[_vm._v("Flight")])]),_c('div',{staticClass:"sectionOneInfo"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200110_11.png"}}),_c('p',[_vm._v("酒店")]),_c('span',[_vm._v("Hotel")])]),_c('div',{staticClass:"sectionOneInfo"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200110_12.png"}}),_c('p',[_vm._v("火车票")]),_c('span',[_vm._v("Train")])]),_c('div',{staticClass:"sectionOneInfo"},[_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200110_13.png"}}),_c('p',[_vm._v("用车")]),_c('span',[_vm._v("Car Service")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionOne"},[_c('div',{staticClass:"sectionOneLeft sectionTitle"},[_c('p',[_vm._v("机票")]),_c('span',[_vm._v("丰富的运价来源，覆盖全球航线，直连多家航司及优质供应商，库存实时准确")])]),_c('div',{staticClass:"sectionOneRight"},[_c('div',{staticClass:"boxLeft right"},[_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("产品丰富")]),_c('span',[_vm._v("提供多样化机票预订产品，比价便捷")]),_c('span',[_vm._v("支持大客户协议托管，退改灵活")]),_c('span',[_vm._v("通过全额行程单，可抵进项税")])]),_c('div',{staticClass:"boxli four"},[_c('p',[_vm._v("智能管控")]),_c('span',[_vm._v("票价、舱等多维度管控设置")]),_c('span',[_vm._v("提前天数预订，特殊管控")])])]),_c('div',{staticClass:"boxLeft"},[_c('div',{staticClass:"boxli on"},[_c('p',[_vm._v("优势资源")]),_c('span',[_vm._v("直连多家航司")]),_c('span',[_vm._v("商旅独享价格优势，90%低于ota")]),_c('span',{staticClass:"on"},[_vm._v("国际机票询价模式，价格更低")])]),_c('div',{staticClass:"boxli two"},[_c('p',[_vm._v("强大技术")]),_c('span',[_vm._v("极速精准搜索航班信息")]),_c('span',[_vm._v("资源PK机制，只输出最低价")]),_c('span',[_vm._v("支持在线订/退/改，自助化操作")]),_c('span',{staticClass:"on"},[_vm._v("超标推荐，快速找到不超标航班")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionTwo"},[_c('div',{staticClass:"sectionTwoLeft"},[_c('div',{staticClass:"boxLeft"},[_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("优势资源")]),_c('span',[_vm._v("覆盖全球120万家酒店")]),_c('span',[_vm._v("直连华住、如家、锦江、亚朵、尚客优等酒店集团")])])]),_c('div',{staticClass:"boxLeft Two"},[_c('div',{staticClass:"boxli on"},[_c('p',[_vm._v("灵活支付")]),_c('span',[_vm._v("对公支付，统一开大发票")]),_c('span',{staticClass:"on"},[_vm._v("支持超标自付，满足员工入住体验")])]),_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("优质体验")]),_c('span',[_vm._v("享含早、无押金、延迟退房等商旅服务")]),_c('span',[_vm._v("房态准确，订单即时确认")]),_c('span',[_vm._v("支持企业协议酒店托管，价格专享")])])]),_c('div',{staticClass:"boxLeft"},[_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("智能管控")]),_c('span',[_vm._v("房价、星级、城市多维度管控设置")]),_c('span',[_vm._v("提前天数预订，特殊管控")])])])]),_c('div',{staticClass:"sectionTwoRight sectionTitle"},[_c('p',[_vm._v("酒店")]),_c('span',[_vm._v("优质OTA资源，直连多家酒店集团实时库存，一秒确认下单")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionThree"},[_c('div',{staticClass:"sectionThreeLeft sectionTitle"},[_c('p',[_vm._v("火车票")]),_c('span',[_vm._v("全面接入12306"),_c('br'),_vm._v("资源统一管控，告别分散预订")])]),_c('div',{staticClass:"sectionThreeRight"},[_c('div',{staticClass:"boxLeft right"},[_c('div',{staticClass:"boxli on"},[_c('p',[_vm._v("优质体验")]),_c('span',[_vm._v("支持在线选座")]),_c('span',[_vm._v("支持多档位抢票")]),_c('span',{staticClass:"on"},[_vm._v("1-10秒内极速出票")])]),_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("智能管控")]),_c('span',[_vm._v("高铁/动车/快车，不同坐席管控设置")]),_c('span',[_vm._v("提前天数预订，特殊管控")])])]),_c('div',{staticClass:"boxLeft"},[_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("预订便捷")]),_c('span',[_vm._v("支持在线订/退/改，自助化操作")]),_c('span',[_vm._v("支持12306账号预订和免登录代订服务")])]),_c('div',{staticClass:"boxli two"},[_c('p',[_vm._v("取票灵活")]),_c('span',[_vm._v("支持电子自取票或纸质配送票")]),_c('span',[_vm._v("商旅代取票，刷身份证进站，票根统一寄送")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionFour"},[_c('div',{staticClass:"sectionFourLeft"},[_c('div',{staticClass:"boxLeft"},[_c('div',{staticClass:"boxli on"},[_c('p',[_vm._v("灵活叫车")]),_c('span',[_vm._v("提供实时叫车、预约叫车两种模式")])])]),_c('div',{staticClass:"boxLeft Two"},[_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("放心乘坐")]),_c('span',[_vm._v("司乘隐私号、一键报警、添加紧急联系人")])]),_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("优质服务")]),_c('span',[_vm._v("同滴滴一样的用车体验")])])]),_c('div',{staticClass:"boxLeft last"},[_c('div',{staticClass:"boxli"},[_c('p',[_vm._v("行程透明")]),_c('span',[_vm._v("行程记录，出行数据透明")]),_c('span',[_vm._v("提供6%增值税专用发票，可抵进项税")])])])]),_c('div',{staticClass:"sectionFourRight sectionTitle"},[_c('p',[_vm._v("用车")]),_c('span',[_vm._v("接入滴滴用车，所有体验同滴滴服务范围覆盖全国")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionFive"},[_c('div',{staticClass:"sectionFiveLeft sectionTitle"},[_c('p',[_vm._v("增值服务")]),_c('span',[_vm._v("差旅管家提供企业团建、定制旅行、会议、团队用房、团队机票、礼品定制等多种增值服务")])]),_c('img',{attrs:{"src":"https://image.ceekee.com/pc/tripwise/20200110_16.png"}})])
}]

export { render, staticRenderFns }